/* src/App.css */
body {
  font-family: 'Roboto', sans-serif;
  max-width: 100vw;
  background-color: #171717;
  color: white;
  background: radial-gradient(50.56% 99.98% at 72.08% 50%, rgba(255, 255, 255, 0.006) 0%, rgba(0, 0, 0, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(225.07deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.01) 74.29%), #171717;
}


.App {
  min-height: 100vh;
  max-width: 1440px;
  padding: 24px;
  margin: auto;
}
.landing {
  position: absolute;
  min-height: calc(100vh - 152px);
}

.landing .row1 {
  display: flex;
}

.landing-cover {
  width: 702px;
}

.landing-cover-phone {
  display: none;
}

.landing-cards {
  max-width: 1440px;
  position: absolute;
  bottom: 48px;
  left: 0px;
  right: 0px;
  height: 107px;
}

.landing-cards-phone {
  display: none;
}

.landing h1 {
  font-size: 64px;
  margin-top: 152px;
  font-family: 'Russo One', 'Roboto', sans-serif;
  opacity: 0.9;
  text-shadow: -4px 4px 4px #000000;
}

.landing p {
  font-size: 16px;
  opacity: 0.9;
}

.landing-buttons {
    display: flex;
    margin-top: 112px;
}

.landing-button {
    background-color: #406E70;
    color: white;
    border: none;
    padding: 0px 20px;
    
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 100px;
    height: 54px;
    line-height: 54px;
    font-size: 14px;
    font-weight: bold;
}

.landing-button:hover {
    background-color: #305a5b;
}

.landing-button-2 {
    background-color: white;
    border-radius: 100px;
    cursor: pointer;
    height: 54px;
    width: 54px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-button-2-img {
  width: 20px;
  height: 20px;
}



/* navbar */

.navbar {
    display: flex;
    align-items: center;
    padding: 10px 0px;
}

.logo {
    height: 64px;
    width: 152px;
}

.logo-phone {
  display: none;
}

.nav-links {
    list-style-type: none;
    display: flex;
    gap: 20px;
}

.nav-links a {
    color: white;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.nav-links a:hover {
    background-color: #555;
}

.nav-subs-title {
  margin: 0px 10px;
  cursor: pointer;
}

.nav-subs-title:hover .nav-subs {
  opacity: 1;
}

.nav-subs {
  opacity: 0;
  display: flex;
  /* background-color: red; */
  position: absolute;
  flex-direction: column;
  margin: 0px -10px 0 -10px;
  padding-top: 16px;
  z-index: 99;
  transition: all 0.3s
}

.nav-sub {
  margin-bottom: 16px;
}

.mobile-pitch-deck-link {
  display: none;
}

@media (max-width: 768px) {
  .App {
    padding: 24px;
    margin: auto;
  }
  .navbar {
    padding: 0;
  }
  /* Nav bar */
  .logo-phone {
    display: block;
    width: 48px;
    height: 48px;
  }
  .logo {
    display: none;
  }
  .nav-links {
    display: none;
  }

  /* Landing Page */
  .landing {
    width: 100vw;
  }
  .landing-cover {
    display: none;
  }
  .landing-cover-phone {
    display: block;
  }
  .row1 {
    position: relative;
    width: calc(100vw - 48px);
    min-height: 89vh;
  }
  .row1-left {
    position: absolute;
    z-index: 2;
  }
  .row1-right {
    position: absolute;
    z-index: 1;
    top: -16px;
    left: -32px;
    right: -32px;
    width: calc(100% + 48px);
    opacity: 0.05;
  }
  .landing h1 {
    font-size: 42px;
    text-align: center;
    margin-top: 116px;
  }
  .landing p {
    text-align: center;
  }
  .landing-buttons {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .landing-button-2 {
    display: none;
  }
  .landing-cards {
    display: none;
  }
  .landing-cards-phone {
    display: block;
    width: calc(100vw - 48px);
    margin-bottom: 24px;
  }
  .mobile-pitch-deck-link {
    display: block;
    color: white;
    margin-top: 24px;
  }
}